<style lang="scss" scoped>
.look-images{
    text-align: center;
}
</style>
<template>
    <div class="look-images">
        <div v-if="imagesUrl">
            <a :href="imagesUrl" target="_blank">
                <look-pdf
                    v-if="fileIsPdf"
                    :src="imagesUrl">
                </look-pdf>
                <el-image
                    v-else
                    :src="imagesUrl">
                </el-image>
            </a>
        </div>
        <div v-else>暂无</div>
    </div>
</template>

<script type="text/ecmascript-6">
import {isFilePdf} from "@/common/js/Utils";

export default {
    //定义模版数据
    data() {
        return {}
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        imagesUrl: {
            type: String,
            default: ''
        }
    },
    components: {
        lookPdf: () => import('vue-pdf')
    },
    //计算属性
    computed: {
        fileIsPdf(){
            let {isPdf} = isFilePdf(this.id, this.imagesUrl)
            return isPdf
        }
    },
}
</script>
