import axios from 'axios'
import http from '../http.js';
import config from '@/config/'

export const up2QiNiu = async function (_this, upKey, file) {
    upKey = (config.Debug ? 'pc/3/dev/' : 'pc/3/pro/') + process.env_project.MODULE_ENV + upKey;
    let protocol = config.protocol;
    let fd = new FormData(); //新建一个formData用来上传
    fd.append('key', upKey); //七牛要求的key字段,实际上包含路径和名字
    fd.append('file', file); //要上传的文件,file格式
    let res = await http('/galaxyQiniu/getToken', {'upKey': upKey}, "POST", true, null, _this);
    if (res.success) {
        fd.append('token', res.data.resultUrl);

        let config = {
            method: 'POST',
            url: `${protocol}//up.qbox.me`,
            data: fd,
            pathName: upKey,
            headers: {
                'Content-Type': undefined
            },
            withCredentials: false
        }
        let rest = await  axios(config);
        if (rest.status === 200)
            return 'https://cdn.900etrip.com/' + rest.data.key
        else
            throw new Error('上传图片失败')
    }
    else
        throw new Error('获取图片上传token失败')
};
