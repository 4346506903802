/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 * 用url方式表示的base64图片数据
 */
const MAX_WIDTH = 320,
    MAX_HEIGHT = 400,
    scale = 2;
let canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");

/*
*入参 Blob对象
*/
const blobToFile = function (blob, name) {
    if (!blob)
        return false;
    let file = blob;
    file.name = name;
    let temp = new Date();
    file.lastModifiedDate = temp;
    file.lastModified = Date.parse(temp);
    return file;
}

/*
IOS 获取图片需要转换的角度
2，4，5，7并不常见，因为它们代表的是镜像方向，
如果不做任何的处理，
不管相机以任何角度拍摄，
都无法出现镜像的情况。
暂未处理
 */
export const rotateImage = function (img, width = 1, height = 1) {
    return new Promise(function (resolve) {
        window.EXIF.getData(img, function () {
            window.EXIF.getAllTags(this);
            let o = window.EXIF.getTag(this, 'Orientation'), degree = 0;
            switch (o) {
                case 3:
                    //横向 反向
                    degree = 180;
                    canvas.width = width
                    canvas.height = height
                    ctx.rotate(degree * Math.PI / 180);
                    ctx.drawImage(img, -width, -height, width, height);
                    break;
                case 6:
                    //竖向 正向
                    degree = 90;
                    canvas.width = height
                    canvas.height = width
                    ctx.rotate(degree * Math.PI / 180);
                    ctx.drawImage(img, 0, -height, width, height);
                    break;
                case 8:
                    //竖向 倒向
                    degree = 270;
                    canvas.width = height
                    canvas.height = width
                    ctx.rotate(degree * Math.PI / 180);
                    ctx.drawImage(img, -width, 0, width, height);
                    break;
                // case 2:
                //     ctx.translate(width, 0);
                //     ctx.scale(-1, 1);
                //     ctx.drawImage(img, 0, 0, width, height);
                //     break;
                // case 4:
                //     degree = 180;
                //     ctx.rotate(degree * Math.PI / 180);
                //     ctx.translate(width, 0);
                //     ctx.scale(-1, 1);
                //     ctx.drawImage(img, -width, -height, width, height);
                //     break;
                // case 5:
                //     degree = 90;
                //     ctx.rotate(degree * Math.PI / 180);
                //     ctx.translate(width, 0);
                //     ctx.scale(-1, 1);
                //     ctx.drawImage(img, 0, -width, height, width);
                //     break;
                // case 7:
                //
                //     degree = 270;
                //     ctx.rotate(degree * Math.PI / 180);
                //     ctx.translate(width, 0);
                //     ctx.scale(-1, 1);
                //     ctx.drawImage(img, -height, 0, height, width);
                //     break;
                default:
                    //横向 正向
                    canvas.width = width
                    canvas.height = height
                    ctx.drawImage(img, 0, 0, width, height);
            }
            resolve(degree)
        })
    })
}

/**
 * 图片压缩  旋转iphone拍照图片
 * @param file
 * 用url方式表示的base64图片数据
 * 返回一个Promise对象 file类型
 */
export const resizeFile = function (file) {
    return new Promise(function (resolve, reject) {
        try {
            if (file.size > 300000) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var img = new Image();
                    img.onload = async function () {

                        let width = img.width,
                            height = img.height;

                        //处理图片大小
                        if (width > MAX_WIDTH) {
                            height = (  MAX_WIDTH / width  ) * height
                            width = MAX_WIDTH;
                        }
                        else if (height > MAX_HEIGHT) {
                            width = (MAX_HEIGHT / height) * width
                            height = MAX_HEIGHT;
                        }


                        canvas.width = width * scale;

                        canvas.height = height * scale;

                        canvas.style.width = width + "px";

                        canvas.style.height = height + "px";

                        ctx.scale(scale, scale)

                        //获取图片EXIF信息
                        if (window.EXIF)
                            await rotateImage(img, width * scale, height * scale);
                        else
                            ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob(function (blob) {
                            resolve(blobToFile(blob, img.name));
                        }, 'image/jpeg', 0.8);


                    }
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            }
            else
                resolve(file)
        } catch (e) {
            reject(e);
        }
    });

};


